import React, { Component } from "react";
import Icon from "@material-ui/core/Icon";
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';


class Nummers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nummers: [],
      nummer: {},
      response: {},
      requestOptions:
        process.env.NODE_ENV === "development"
          ? {}
          : {
              headers: {
                Authorization: `Basic ${JSON.parse(sessionStorage.getItem("user")).authdata}`
              }
            }
    };
  }

  componentDidMount() {
    //with headers to be added later

    fetch(
      process.env.REACT_APP_API_URL +
        "nummers",
      this.state.requestOptions
    )
      .then(res => res.json())
      .then(data => {
        console.log(data);
        this.setState({ nummers: data });
      })
      .catch(console.log);
    
  }

  render() {
    const { nummers } = this.state;
    return (
      <div>
        <br></br>
        <center>
          <h1>Nuttige nummers</h1>
        </center>
        <h2>Extern</h2>
        {nummers
          .filter(nr => !nr.intern)
          .map(nummer => (
            <div key={nummer.id} className="card">
              <div className="card-body">
                <h5 className="card-title">{nummer.naam}</h5>
                <h6 className="card-text">
                  <PhoneIphoneIcon/>
                  <a href={"tel:" + nummer.nummer}>{nummer.nummer}</a>
                </h6>
              </div>
            </div>
          ))}
        <br></br>
        <h2>Intern</h2>
        {nummers
          .filter(nr => nr.intern)
          .map(nummer => (
            <div key={nummer.id} className="card">
              <div className="card-body">
                <h5 className="card-title">{nummer.naam}</h5>
                <h6 className="card-text">
                  <Icon>phone</Icon>
                  {nummer.nummer}
                </h6>
              </div>
            </div>
          ))}
      </div>
    );
  }
}
export default Nummers;
