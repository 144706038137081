import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const RouteGuard = ({ component: Component, roles: Roles, ...rest }) => 
{
return(
    <Route {...rest} render={props => (
        
        sessionStorage.getItem('user')
            ? ((JSON.parse(sessionStorage.getItem("user"))).roles.some(r => Roles.indexOf(r) >= 0)
            ? <Component {...props} /> 
            : <Redirect to={{ pathname: '/app/klanten', state: { from: props.location } }} />)
            : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    )} />
)};

export default RouteGuard;