import React, { Component } from "react";
import DOMPurify from "dompurify";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import RichTextEditor from "react-rte";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(fas, fab)

class Announcements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      edit: false,
      error: null,
      announcements: [],
      groups: [],
      announcement: {},
      editAnnouncementTitle: "",
      editAnnouncementText: RichTextEditor.createEmptyValue(),
      editAnnouncementGroup: "",
      nummer: {},
      response: {},
      user: JSON.parse(sessionStorage.getItem("user")),
      requestOptions:
        process.env.NODE_ENV === "development"
          ? {}
          : {
              headers: {
                Authorization: `Basic ${
                  JSON.parse(sessionStorage.getItem("user")).authdata
                }`
              }
            }
    };

    this.updateAnnouncementTitle = this.updateAnnouncementTitle.bind(this);
    this.updateAnnouncementText = this.updateAnnouncementText.bind(this);
    this.updateAnnouncementGroup = this.updateAnnouncementGroup.bind(this);
  }

  componentDidMount() {
    this.fetchAnnouncements();
    this.fetchGroups();
  }

  fetchAnnouncements() {
    var t = this;
    fetch(
      process.env.REACT_APP_API_URL + "announcements",
      this.state.requestOptions
    )
      .then(res => res.json())
      .then(data => {
        var list = [];
        data.forEach(record => {
          if (record.posterId === t.state.user.id) {
            list.push(record);
          } else {
            record.recipientgroupNavigation.groupUsers.forEach(user => {
              if (user.userId === t.state.user.id) {
                list.push(record);
              }
            });
          }
        });

        this.setState({ announcements: list });
      })
      .catch(console.log);
  }

  fetchGroups() {
    fetch(process.env.REACT_APP_API_URL + "groups", this.state.requestOptions)
      .then(res => res.json())
      .then(data => {
        this.setState({ groups: data });
      })
      .catch(console.log);
  }

  addNewAnnouncement() {
    var announcement = {
      recipientgroup: 0,
      text: "",
      title: ""
    };
    this.toggleModal(announcement);
    this.toggleEdit(announcement);
  }

  toggleModal(announcement) {
    if (announcement !== null) {
      this.setState({
        announcement: announcement
      });
    }

    this.setState({
      isOpen: !this.state.isOpen,
      edit: false
    });
  }

  toggleEdit(announcement) {
    this.setState({
      edit: !this.state.edit,
      editAnnouncementGroup: announcement.recipientgroup,
      editAnnouncementText: RichTextEditor.createValueFromString(
        announcement.text,
        "html"
      ),
      editAnnouncementTitle: announcement.title
    });
  }

  updateAnnouncementTitle(event) {
    this.setState({ editAnnouncementTitle: event.target.value });
  }

  updateAnnouncementText(value) {
    this.setState({ editAnnouncementText: value });
  }

  updateAnnouncementGroup(event) {
    this.setState({ editAnnouncementGroup: event.target.value });
  }

  save(event) {
    var t = this;
    if (this.state.editAnnouncementGroup !== 0) {
      axios
        .post(
          process.env.REACT_APP_API_URL + "announcements",
          {
            title: this.state.editAnnouncementTitle,
            text: this.state.editAnnouncementText.toString("html"),
            recipientgroup: this.state.editAnnouncementGroup,
            posterId: this.state.user.id,
            date: new Date()
              .toISOString()
              .slice(0, 19)
              .replace("T", " ")
          },
          this.state.requestOptions
        )
        .then(function(response) {
          t.fetchAnnouncements();
        });

      this.toggleModal(this.state.announcement);
    }
  }

  delete(announcement) {
    var t = this;
    axios
      .delete(
        process.env.REACT_APP_API_URL + "announcements/" + announcement.id,
        this.state.requestOptions
      )
      .then(function() {
        t.fetchAnnouncements();
      });
  }

  render() {
    const { announcements, groups, user } = this.state;
    return (
      <div>
        <br></br>
        <center>
          <h1>Mededelingen</h1>
        </center>
        <Button
          hidden={!(this.state.user.roles.some(r => r === "Alle gebruikers"))}
          variant="primary mb-3"
          onClick={() => this.addNewAnnouncement()}
        >
          Nieuwe mededeling
        </Button>
        {announcements.map(announcement => (
          <Card key={announcement.id}>
            <Card.Body>
              <Card.Title>{announcement.title}<FontAwesomeIcon title={announcement.recipientgroupNavigation.groepsnaam} style={{float: "right"}} icon={[announcement.recipientgroupNavigation.icon? announcement.recipientgroupNavigation.icon.split('%')[0] : "fas", announcement.recipientgroupNavigation.icon ? announcement.recipientgroupNavigation.icon.split('%')[1] : "user-friends"]} /></Card.Title>

              <Card.Text
                className="content"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(announcement.text)
                }}
              ></Card.Text>
            </Card.Body>
            <Card.Footer style={{fontSize: "0.9em"}}>
              {announcement.poster.name} - {new Intl.DateTimeFormat("nl-NL", {
                  year: "numeric",
                  month: "long",
                  day: "2-digit"
                }).format(new Date(announcement.date))}
                
              <Button
                hidden={
                  !(
                    user.roles.some(r => r === "Administratoren")
                  )
                }
                variant="danger"
                size="sm"
                className="float-right"
                onClick={() => this.delete(announcement)}
              >
                Verwijder
              </Button>
            </Card.Footer>
          </Card>
        ))}
        <Modal
          show={this.state.isOpen}
          onClose={() => this.toggleModal(this.state.announcement)}
        >
          <Modal.Body>
          <h1>Mededeling</h1>
          <Form.Group>
                <Form.Label>Titel</Form.Label>
                <Form.Control
              type="text"
              placeholder=""
              value={this.state.editAnnouncementTitle}
              onChange={this.updateAnnouncementTitle}
            />
          </Form.Group>
          <Form.Group>
                <Form.Label>Text</Form.Label>
                
            <RichTextEditor
              value={this.state.editAnnouncementText}
              onChange={this.updateAnnouncementText}
            />
          </Form.Group>
          <Form.Group>
                <Form.Label>Ontvangers</Form.Label>
                <Form.Control
              as="select"
              placeholder=""
              value={this.state.editAnnouncementGroup}
              onChange={this.updateAnnouncementGroup}
            >
              <option key={"0"} name="none" value={0}>
                -- Selecteer groep --
              </option>
              {groups
                .map(group => (
                  <option
                    key={group.id}
                    name={group.groepsnaam}
                    value={group.id}
                  >
                    {group.groepsnaam}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>
          <br />
          <div className="footer">
            <Button
              className="float-right"
              variant="primary"
              onClick={() => this.toggleModal(this.state.Announcement)}
            >
              Sluit
            </Button>
            <Button
              className="float-right mr-1"
              hidden={!this.state.edit}
              variant="info"
              onClick={() => this.save()}
            >
              Opslaan
            </Button>
          </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default Announcements;
