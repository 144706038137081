import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import "./style.css";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Form from "react-bootstrap/Form";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";

class Gebruikers extends Component {
  constructor(props) {
    super(props);
    this.timeout = 0;
    this.state = {
      isOpen: false,
      hasMore: true,
      items: [],
      edit: false,
      error: null,
      gebruikers: [],
      all: [],
      gebruiker: {},
      editgebruikerNaam: "",
      editgebruikerLogin: "",
      editgebruikerEmail: "",
      editgebruikerPassword: "",
      editgebruikerPasswordCheck: "",
      editgebruikerSendMail: false,
      response: {},
      itemIndex: 0,
      user: JSON.parse(sessionStorage.getItem("user")),
      errorMessage: "",
      submitted: false,
      requestOptions:
        process.env.NODE_ENV === "development"
          ? {}
          : {
              headers: {
                Authorization: `Basic ${
                  JSON.parse(sessionStorage.getItem("user")).authdata
                }`
              }
            }
    };
    this.handleChange = this.handleChange.bind(this);
    this.updateGebruikerNaam = this.updateGebruikerNaam.bind(this);
    this.updateGebruikerLogin = this.updateGebruikerLogin.bind(this);
    this.updateGebruikerEmail = this.updateGebruikerEmail.bind(this);
    this.updateGebruikerPassword = this.updateGebruikerPassword.bind(this);
    this.updateGebruikerSendMail = this.updateGebruikerSendMail.bind(this);
    this.updateGebruikerPasswordCheck = this.updateGebruikerPasswordCheck.bind(
      this
    );
  }

  handleChange(e) {
    var searchText = e.target.value;
    let currentList = [];
    let newList = [];
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (searchText !== "") {
        currentList = this.state.all;

        newList = currentList.filter(item => {
          const name = item.name.toLowerCase();
          const email = item.email.toLowerCase();
          const login = item.username.toLowerCase();
          const filter = searchText.toLowerCase();

          return (
            name.includes(filter) ||
            email.includes(filter) ||
            login.includes(filter)
          );
        });

        this.setState({
          gebruikers: newList,
          itemIndex: 0,
          items: newList.splice(0, 20),
          hasMore: true
        });
      } else {
        this.fetchgebruikers();
      }
    }, 300);
  }

  validateMail(email){
    const expression = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

    return expression.test(String(email).toLowerCase())
  }

  componentDidMount() {
    this.fetchgebruikers();
  }

  fetchgebruikers() {
    fetch(process.env.REACT_APP_API_URL + "users", this.state.requestOptions)
      .then(res => res.json())
      .then(data => {
        this.setState({
          gebruikers: data,
          all: data,
          items: data.slice(0, 20)
        });
      })
      .catch(console.log);
  }

  toggleModal(gebruiker) {
    if (gebruiker !== null) {
      this.setState({
        gebruiker: gebruiker
      });
    }

    this.setState({
      isOpen: !this.state.isOpen,
      edit: false
    });
  }

  fetchMoreData = () => {
    if (this.state.items.length >= this.state.gebruikers.length) {
      this.setState({ hasMore: false });
      return;
    }
    this.setState({
      itemIndex: this.state.itemIndex + 1
    });
    // a fake async api call like which sends
    // 20 more records in .5 secs
    setTimeout(() => {
      this.setState({
        items: this.state.items.concat(
          this.state.gebruikers.slice(
            this.state.itemIndex * 20,
            this.state.itemIndex * 20 + 20
          )
        )
      });
    }, 500);
  };

  addNewgebruiker() {
    var gebruiker = {
      id: 0,
      Name: "",
      email: "",
      username: ""
    };
    this.toggleModal(gebruiker);
    this.toggleEdit(gebruiker);
  }

  toggleEdit(gebruiker) {
    this.setState({
      edit: !this.state.edit,
      editgebruikerNaam: gebruiker.name,
      editgebruikerEmail: gebruiker.email,
      editgebruikerLogin: gebruiker.username,
      editgebruikerPassword: "",
      editgebruikerPasswordCheck: "",
      editgebruikerSendMail: false,
      errorMessage: "",
      submitted: false
    });
  }

  updateGebruikerNaam(event) {
    this.setState({ editgebruikerNaam: event.target.value });
  }
  updateGebruikerEmail(event) {
    this.setState({ editgebruikerEmail: event.target.value });
  }
  updateGebruikerLogin(event) {
    this.setState({ editgebruikerLogin: event.target.value });
  }
  updateGebruikerPassword(event) {
    this.setState({ editgebruikerPassword: event.target.value });
  }
  updateGebruikerSendMail(event) {
    this.setState({ editgebruikerSendMail: event.target.checked });
  }
  updateGebruikerPasswordCheck(event) {
    this.setState({ editgebruikerPasswordCheck: event.target.value });
  }

  save(event) {
    this.setState({ submitted: true });
    if (
      !(
        this.state.editgebruikerLogin &&
        this.state.editgebruikerNaam &&
        this.state.editgebruikerEmail
      )
    ) {
      return;
    }

    if(!this.validateMail(this.state.editgebruikerEmail)){
      this.setState({
        errorMessage: "Emailadres niet geldig"
      });
      return;
    }

    //check login
    var loginCheck = true;

    this.state.gebruikers.forEach(gebruiker => {
      if (
        gebruiker.username.toLowerCase() ===
          this.state.editgebruikerLogin.toLowerCase() &&
        gebruiker.id !== this.state.gebruiker.id
      ) {
        loginCheck = false;
      }
    });

    if (!loginCheck) {
      this.setState({
        errorMessage: "Gebruikersnaam reeds in gebruik"
      });
      return;
    }

    this.setState({ errorMessage: "" });
    if (
      this.state.editgebruikerPassword === this.state.editgebruikerPasswordCheck
    ) {
      var t = this;

      if (this.state.gebruiker.id !== 0) {
        var edituser = {
          id: this.state.gebruiker.id,
          username: this.state.editgebruikerLogin,
          name: this.state.editgebruikerNaam,
          email: this.state.editgebruikerEmail,
          userLevel: 4,
          active: this.state.gebruiker.active,
          pwchanged: this.state.gebruiker.pwchanged
        };

        axios
          .put(
            process.env.REACT_APP_API_URL + "users/" + this.state.gebruiker.id,
            edituser,
            this.state.requestOptions
          )
          .then(function(response) {
            if (edituser.id === t.state.user.id) {
              t.props.history.push("/login");
            }
            t.fetchgebruikers();
          });
        this.toggleModal(this.state.gebruiker);
      } else {
        if (this.state.editgebruikerPassword !== "") {
          axios
            .post(
              process.env.REACT_APP_API_URL + "users",
              {
                username: this.state.editgebruikerLogin,
                name: this.state.editgebruikerNaam,
                email: this.state.editgebruikerEmail,
                userLevel: 4,
                password: this.state.editgebruikerPassword,
                active: 1,
                pwchanged: 0
              },
              this.state.requestOptions
            )
            .then(function(response) {
              //Add user to all Users
              var newuserID = response.data.id;
              if (t.state.editgebruikerSendMail) {
                axios.post(
                  process.env.REACT_APP_API_URL + "mail",
                  {
                    userId: response.data.id,
                    password: t.state.editgebruikerPassword
                  },
                  t.state.requestOptions
                );
              }
              axios
                .post(
                  process.env.REACT_APP_API_URL + "groupUsers",
                  {
                    userId: newuserID,
                    groupId: 3
                  },
                  t.state.requestOptions
                )
                .then(function(response) {
                  t.fetchgebruikers();
                });
            });

          this.toggleModal(this.state.gebruiker);
        } else {
          this.setState({
            errorMessage: "Wachtwoord moet ingevuld zijn voor nieuwe gebruikers"
          });
        }
      }
    } else {
      this.setState({ errorMessage: "Wachtwoorden komen niet overeen" });
    }
  }

  toggleActive(gebruiker) {
    var edituser = {
      id: this.state.gebruiker.id,
      username: this.state.gebruiker.username,
      name: this.state.gebruiker.name,
      email: this.state.gebruiker.email,
      userLevel: this.state.gebruiker.userLevel,
      active: this.state.gebruiker.active,
      password: this.state.gebruiker.password
    };

    if (edituser.active === 1) {
      edituser.active = 0;
    } else {
      edituser.active = 1;
    }
    var t = this;
    axios
      .put(
        process.env.REACT_APP_API_URL + "users/active/" + gebruiker.id,
        this.state.requestOptions
      )
      .then(function(response) {
        t.fetchgebruikers();
      });
    this.toggleModal(this.state.gebruiker);
  }

  deleteClient(gebruiker) {
    var t = this;
    axios
      .delete(
        process.env.REACT_APP_API_URL + "users/" + gebruiker.id,
        this.state.requestOptions
      )
      .then(function() {
        t.fetchgebruikers();
      });
    this.toggleModal(null);
  }

  getUserLevelString(value) {
    switch (value) {
      case 1:
        return "Read";
      case 2:
        return "Read, Create";
      case 3:
        return "Read, Create, Modify";
      case 4:
        return "Admin";
      case 5:
        return "Developer";
      default:
        return "Read";
    }
  }

  render() {
    return (
      <div>
        <br></br>
        <center>
          <h1>Gebruikers</h1>
        </center>
        <InputGroup className="mb-3">
          <InputGroup.Prepend>
            <InputGroup.Text>Zoek gebruikers</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl onChange={this.handleChange} />
        </InputGroup>
        <Button variant="primary mb-3" onClick={() => this.addNewgebruiker()}>
          Nieuwe gebruiker toevoegen
        </Button>
        <InfiniteScroll
          dataLength={this.state.items.length}
          next={this.fetchMoreData}
          hasMore={this.state.hasMore}
          loader={<h4>Loading...</h4>}
        >
          {this.state.items.map(gebruiker => (
            <div
              className="card"
              key={gebruiker.id}
              onClick={() => this.toggleModal(gebruiker)}
            >
              <div className={"card-body " + (gebruiker.active ? "" : "light")}>
                <h5 className="card-title">{gebruiker.name}</h5>
                <h6 className="card-text">
                  <b>Email:</b> {gebruiker.email}
                </h6>
                <h6 className="card-text">
                  <b>Actief:</b> {gebruiker.active ? "Ja" : "Neen"}
                </h6>
              </div>
            </div>
          ))}
        </InfiniteScroll>
        <br />
        <br />

        <Modal
          onHide={() => this.toggleModal(this.state.gebruiker)}
          show={this.state.isOpen}
          onClose={() => this.toggleModal(this.state.gebruiker)}
        >
          <Modal.Body>
            <div class="container"  hidden={this.state.edit}>
                <div class="row">
                  <div class="col-12">
                    <h3 class="klant-detail-title">Gebruiker Details</h3>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-12">
                    <p class="mb-0 title-item">Naam</p>
                    <p class="detail-item">{this.state.gebruiker.name}</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-5">
                    <p class="mb-0 title-item">Gebruikersnaam</p>
                    <p class="detail-item">{this.state.gebruiker.username}</p>
                  </div>
                  <div class="col-7">
                    <p class="mb-0 title-item">Emailadres</p>
                    <p class="detail-item">{this.state.gebruiker.email}</p>
                  </div>
                  </div>
                <div class="row">
                  <div class="col-4">
                    <p class="mb-0 title-item">Actief</p>
                    <p class="detail-item">{this.state.gebruiker.active ? "Ja" : "Neen"}</p>
                  </div>
                </div>
                <hr/>
              </div>
            <div hidden={!this.state.edit}>
              <Form.Group>
                <Form.Label>Naam</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  value={this.state.editgebruikerNaam}
                  onChange={this.updateGebruikerNaam}
                />
                {this.state.submitted && !this.state.editgebruikerNaam && (
                  <Form.Text className="text-muted mb-2 mt-0">
                    Naam is verplicht
                  </Form.Text>
                )}
              </Form.Group>

              <Form.Group>
                <Form.Label>Gebruikersnaam</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  value={this.state.editgebruikerLogin}
                  onChange={this.updateGebruikerLogin}
                />
                {this.state.submitted && !this.state.editgebruikerLogin && (
                  <Form.Text className="text-muted mb-2">
                    Gebruikersnaam is verplicht
                  </Form.Text>
                )}
              </Form.Group>

              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder=""
                  value={this.state.editgebruikerEmail}
                  onChange={this.updateGebruikerEmail}
                />
                {this.state.submitted && !this.state.editgebruikerEmail && (
                  <Form.Text className="text-muted mb-2">
                    Email is verplicht
                  </Form.Text>
                )}
              </Form.Group>

              <div
                style={{
                  display: this.state.gebruiker.id !== 0 ? "none" : "block"
                }}
              >
                <Form.Group>
                  <Form.Label>Wachtwoord</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder=""
                    value={this.state.editgebruikerPassword}
                    onChange={this.updateGebruikerPassword}
                  />
                  {this.state.submitted &&
                    !this.state.editgebruikerPassword &&
                    this.state.gebruiker.id === 0 && (
                      <Form.Text className="text-muted mb-2">
                        Wachtwoord is verplicht
                      </Form.Text>
                    )}
                </Form.Group>

                <Form.Group>
                  <Form.Label>Herhaal wachtwoord</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder=""
                    value={this.state.editgebruikerPasswordCheck}
                    onChange={this.updateGebruikerPasswordCheck}
                  />
                  {this.state.submitted &&
                    !this.state.editgebruikerPasswordCheck &&
                    this.state.gebruiker.id === 0 && (
                      <Form.Text className="text-muted mb-2">
                        Wachtwoord check is verplicht
                      </Form.Text>
                    )}
                </Form.Group>

                <Form.Group controlId="formBasicCheckbox">
                  <Form.Check
                    custom
                    type="checkbox"
                    label="Stuur welkom mail met inloggegevens."
                    checked={this.state.editgebruikerSendMail}
                    onChange={this.updateGebruikerSendMail}
                  />
                </Form.Group>
              </div>
              {this.state.errorMessage && (
                <div className={"alert alert-danger"}>
                  {this.state.errorMessage}
                </div>
              )}
            </div>
            <br />
            <div className="footer">
              <Button
                className="float-right"
                variant="primary"
                onClick={() => this.toggleModal(this.state.gebruiker)}
              >
                Sluit
              </Button>
              <Button
                className="float-right mr-1"
                hidden={!this.state.edit}
                variant="info"
                onClick={() => this.save()}
              >
                Opslaan
              </Button>
              <Button
                className="float-right mr-1"
                hidden={
                  !(
                    !this.state.edit &&
                    this.state.user.roles.some(r => r === "Administratoren")
                  )
                }
                variant="info"
                onClick={() => this.toggleEdit(this.state.gebruiker)}
              >
                Wijzig
              </Button>
              <Button
                className="float-right mr-1"
                hidden={
                  !(
                    !this.state.edit &&
                    this.state.user.roles.some(r => r === "Administratoren")
                  )
                }
                variant="info"
                onClick={() => this.toggleActive(this.state.gebruiker)}
              >
                {this.state.gebruiker.active ? "Blokkeer" : "Activeer"}
              </Button>
              <Button
                className="float-right mr-1"
                hidden={
                  !(
                    !this.state.edit &&
                    this.state.user.roles.some(r => r === "Administratoren")
                  )
                }
                variant="danger"
                onClick={() => this.deleteClient(this.state.gebruiker)}
              >
                Verwijder
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default Gebruikers;
