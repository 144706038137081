import React from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import logo from "./imgs/logo.png";
import { userService } from "../services/user.service";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    userService.logout();

    this.state = {
      username: "",
      password: "",
      submitted: false,
      loading: false,
      error: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true});
    const { username, password } = this.state;

    // stop here if form is invalid
    if (!(username && password)) {
      return;
    }

    this.setState({ loading: true });
    userService.login(username, password).then(
      user => {
        if (user.id) {
          console.log("User logged in");
          if (user.pwchanged) {
            const { from } = this.props.location.state || {
              from: { pathname: "/app/" }
            };
            this.props.history.push(from);
          } else {
            this.props.history.push("/changepassword");
          }
        } else {
          this.setState({
            error: "Gebruikersnaam of wachtwoord incorrect",
            loading: false
          });
        }
      },
      error => this.setState({ error, loading: false })
    );
  }

  render() {
    const { username, password, submitted, loading, error } = this.state;
    return (
      <div className="App-bg">
        <div className="App">
          <br></br>
          <br></br>
          <Container>
            <Card>
              <Card.Body>
                <Row>
                  <Col></Col>
                  <Col xs="8" md="4">
                    <Image src={logo} fluid />
                  </Col>
                  <Col></Col>
                </Row>
                <Row>
                  <Col></Col>
                  <Col xs="12" md="6">
                <form name="form" onSubmit={this.handleSubmit}>
                  <div
                    className={
                      "form-group" +
                      (submitted && !username ? " has-error" : "")
                    }
                  >
                    <label htmlFor="username">Gebruikersnaam</label>
                    <input
                      type="text"
                      className="form-control"
                      name="username"
                      value={username}
                      onChange={this.handleChange}
                    />
                    {submitted && !username && (
                      <div className="help-block">
                        Gebruikersnaam is verplicht
                      </div>
                    )}
                  </div>
                  <div
                    className={
                      "form-group" +
                      (submitted && !password ? " has-error" : "")
                    }
                  >
                    <label htmlFor="password">Wachtwoord</label>
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      value={password}
                      onChange={this.handleChange}
                    />
                    {submitted && !password && (
                      <div className="help-block">Wachtwoord is verplicht</div>
                    )}
                  </div>
                  <div className="form-group">
                    <button className="btn btn-primary" disabled={loading}>
                      Log in
                    </button>
                    {loading && 
                    <>&nbsp;
                    <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner></>
                    }
                  </div>
                  {error && <div className={"alert alert-danger"}>{error}</div>}
                </form>
              
                  </Col>
                  <Col></Col>
                </Row>
                </Card.Body>
            </Card>
          </Container>
        </div>
      </div>
    );
  }
}

export { LoginPage };
