import React from "react";
import ReactImageMagnify from "react-image-magnify";
import flowchart from "./imgs/Flowchart.png";

class Flowchart extends React.Component {
  render() {
    return (
      <>
      <br></br>
        <center>
          <h1>Flowchart Scania</h1>
        </center>
        <ReactImageMagnify style={{border: "inset #325D88 1px"}}
          {...{
            enlargedImagePosition: 'over',
            smallImage: {
              alt: "Flowchart buga",
              isFluidWidth: true,
              src: flowchart
            },
            largeImage: {
              src: flowchart,
              width: 1540,
              height: 800
            }
          }}
        />
        
      </>
    );
  }
}

export { Flowchart };
