//import { authHeader } from '../helpers/auth-header';
import axios from "axios";

export const userService = {
  login,
  logout
};

function login(username, password) {
  return axios
    .post(
      process.env.REACT_APP_API_URL + "users/login",
      {
        username: username,
        password: password
      }
    )
    .then(result => {
        var data = {};
        if (result.data) {
          data = result.data;
          data.authdata = window.btoa(username + ":" + password);
          data.roles = [];
          data.groupUsers.forEach(groupUser =>{
            data.roles.push(groupUser.group.groepsnaam)
          });
          sessionStorage.setItem("user", JSON.stringify(data));
          return data;
        }
    },
    e => {
      return e;
    });
}

function logout() {
  // remove user from local storage to log user out
  sessionStorage.removeItem("user");
}

