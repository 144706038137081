import React, { Component } from "react";
import "./style.css";
import { Button, Modal, Badge, InputGroup, Form } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";

library.add(fas, fab);

class Groepen extends Component {
  constructor(props) {
    super(props);
    this.timeout = 0;
    this.state = {
      isOpen: false,
      hasMore: true,
      items: [],
      error: null,
      groepen: [],
      newGroep: "",
      newGroepIcon: {
        value: "fas%user-friends",
        label: (
            <FontAwesomeIcon
              title={"user-friends"}
              icon={["fas", "user-friends"]}
            />
        ),
      },
      all: [],
      groep: {},
      users: [],
      response: {},
      itemIndex: 0,
      user: JSON.parse(sessionStorage.getItem("user")),
      errorMessage: "",
      requestOptions:
        process.env.NODE_ENV === "development"
          ? {}
          : {
              headers: {
                Authorization: `Basic ${
                  JSON.parse(sessionStorage.getItem("user")).authdata
                }`,
              },
            },
      iconOptions: [
        {
          value: "fas%user-friends",
          label: (
              <FontAwesomeIcon
                title={"user-friends"}
                icon={["fas", "user-friends"]}
              />
          ),
        },
        {
          value: "fas%user-tie",
          label: (
              <FontAwesomeIcon
                title={"user-tie"}
                icon={["fas", "user-tie"]}
              />
          ),
        },{
          value: "fas%user-tag",
          label: (
              <FontAwesomeIcon
                title={"user-tag"}
                icon={["fas", "user-tag"]}
              />
          ),
        },{
          value: "fas%user-shield",
          label: (
              <FontAwesomeIcon
                title={"user-shield"}
                icon={["fas", "user-shield"]}
              />
          ),
        },{
          value: "fas%user-lock",
          label: (
              <FontAwesomeIcon
                title={"user-lock"}
                icon={["fas", "user-lock"]}
              />
          ),
        },{
          value: "fas%user-clock",
          label: (
              <FontAwesomeIcon
                title={"user-clock"}
                icon={["fas", "user-clock"]}
              />
          ),
        },{
          value: "fas%user-secret",
          label: (
              <FontAwesomeIcon
                title={"user-secret"}
                icon={["fas", "user-secret"]}
              />
          ),
        },{
          value: "fas%user-astronaut",
          label: (
              <FontAwesomeIcon
                title={"user-astronaut"}
                icon={["fas", "user-astronaut"]}
              />
          ),
        },{
          value: "fas%house-user",
          label: (
              <FontAwesomeIcon
                title={"house-user"}
                icon={["fas", "house-user"]}
              />
          ),
        }
      ],
    };

  }

  componentDidMount() {
    this.fetchgroepen();
    this.fetchUsers();
  }

  fetchgroepen() {
    fetch(process.env.REACT_APP_API_URL + "groups", this.state.requestOptions)
      .then((res) => res.json())
      .then((data) => {
        data.forEach((groep) => {
          groep.userToAdd = 0;
        });

        this.setState({
          groepen: data,
          all: data,
          items: data.slice(0, 20),
        });
      })
      .catch(console.log);
  }

  fetchUsers() {
    fetch(process.env.REACT_APP_API_URL + "users", this.state.requestOptions)
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          users: data,
        });
      })
      .catch(console.log);
  }

  getUnusedUsers(group) {
    var rUsers = [];
    var userids = group.groupUsers.map((a) => a.user.id);
    this.state.users.forEach((user) => {
      if (!userids.includes(user.id)) {
        rUsers.push(user);
      }
    });
    return rUsers;
  }

  fetchMoreData = () => {
    if (this.state.items.length >= this.state.groepen.length) {
      this.setState({ hasMore: false });
      return;
    }
    this.setState({
      itemIndex: this.state.itemIndex + 1,
    });
    // a fake async api call like which sends
    // 20 more records in .5 secs
    setTimeout(() => {
      this.setState({
        items: this.state.items.concat(
          this.state.groepen.slice(
            this.state.itemIndex * 20,
            this.state.itemIndex * 20 + 20
          )
        ),
      });
    }, 500);
  };

  deleteGroupUser(id) {
    var t = this;
    axios
      .delete(
        process.env.REACT_APP_API_URL + "groupusers/" + id,
        this.state.requestOptions
      )
      .then(function () {
        t.fetchgroepen();
      });
  }

  deleteGroup(id) {
    var t = this;
    axios
      .delete(
        process.env.REACT_APP_API_URL + "groups/" + id,
        this.state.requestOptions
      )
      .then(function () {
        t.fetchgroepen();
      });
  }

  updateGroupToAdd(e, ev) {
    var groepen = this.state.groepen;
    groepen.find((gr) => gr.id === e).userToAdd = ev.target.value;
    this.setState({ groepen: groepen });
  }

  updateNewGroup(e) {
    this.setState({ newGroep: e.target.value });
  }

  updateNewGroupIcon = value => {
    this.setState({ newGroepIcon: value })
  }

  addGroep() {
    var naam = this.state.newGroep;
    var icon = this.state.newGroepIcon.value;
    var t = this;
    if (naam !== "") {
      axios
        .post(
          process.env.REACT_APP_API_URL + "groups",
          {
            groepsnaam: naam,
            canSelect: 2,
            icon: icon
          },
          this.state.requestOptions
        )
        .then(function () {
          t.fetchgroepen();
          t.toggleModal();
        });
    }
  }

  toggleModal() {
    this.setState({
      newGroep: "",
      newGroepIcon: {
        value: "fas%user-friends",
        label: (
            <FontAwesomeIcon
              title={"user-friends"}
              icon={["fas", "user-friends"]}
            />
        ),
      },
      isOpen: !this.state.isOpen,
      edit: false,
    });
  }

  addUserToGroep(e) {
    var gr = e;
    var gebr = this.state.groepen.find((gr) => gr.id === e).userToAdd;
    if (gebr !== 0) {
      var t = this;
      axios
        .post(
          process.env.REACT_APP_API_URL + "groupusers",
          {
            userId: gebr,
            groupId: gr,
          },
          this.state.requestOptions
        )
        .then(function () {
          t.fetchgroepen();
        });
    }
  }

  render() {
    return (
      <>
        <div>
          <br></br>
          <center>
            <h1>Groepen</h1>
          </center>

          <Button
            variant="primary"
            className="mb-3"
            onClick={() => this.toggleModal()}
          >
            Nieuwe groep
          </Button>
          <InfiniteScroll
            dataLength={this.state.items.length}
            next={this.fetchMoreData}
            hasMore={this.state.hasMore}
            loader={<h4>Loading...</h4>}
          >
            {this.state.items.map((groep) => (
              <div
                className="card"
                key={groep.id}
                style={{ minHeight: "250px" }}
              >
                <div className={"card-body"}>
                  <h5 className="card-title">
                    {groep.groepsnaam}
                    <FontAwesomeIcon
                      title={groep.groepsnaam}
                      style={{ float: "right" }}
                      icon={[
                        groep.icon ? groep.icon.split("%")[0] : "fas",
                        groep.icon ? groep.icon.split("%")[1] : "user-friends",
                      ]}
                    />
                  </h5>
                  {groep.groupUsers.map((user) => (
                    <>
                      <Badge key={user.id} variant="light">
                        {user.user.name}&nbsp;&nbsp;&nbsp;
                        <Button
                          variant="secondary"
                          size="sm"
                          style={{ padding: "0px 2px" }}
                          onClick={() => this.deleteGroupUser(user.id)}
                        >
                          X
                        </Button>
                      </Badge>{" "}
                    </>
                  ))}
                  <br />
                  <InputGroup
                    className="mt-3"
                    size="sm"
                    style={{
                      position: "absolute",
                      bottom: "10px",
                      width: "90%",
                    }}
                  >
                    <Form.Control
                      as="select"
                      placeholder=""
                      value={groep.userToAdd}
                      onChange={this.updateGroupToAdd.bind(this, groep.id)}
                    >
                      <option key="0" name="Select User" value={0}>
                        Select User
                      </option>
                      {this.getUnusedUsers(groep).map((u) => (
                        <option key={u.id} name={u.name} value={u.id}>
                          {u.name}
                        </option>
                      ))}
                    </Form.Control>
                    <InputGroup.Append>
                      <Button
                        variant="primary"
                        onClick={this.addUserToGroep.bind(this, groep.id)}
                      >
                        Toevoegen
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                </div>
              </div>
            ))}
          </InfiniteScroll>
          <br />
          <br />
        </div>
        <Modal
          onHide={() => this.toggleModal()}
          show={this.state.isOpen}
          onClose={() => this.toggleModal()}
        >
          <Modal.Body>
            <h1>Nieuwe groep</h1>

            <Form.Group>
              <Form.Label>Groepsnaam</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={this.state.newGroep}
                onChange={this.updateNewGroup.bind(this)}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Icoon</Form.Label>
              <Select
                value={this.state.newGroepIcon}
                onChange={this.updateNewGroupIcon}
                options={this.state.iconOptions}
              />
            </Form.Group>

            <div className="footer">
              <Button
                className="float-right"
                variant="primary"
                onClick={() => this.toggleModal()}
              >
                Sluit
              </Button>
              <Button
                className="float-right  mr-1"
                variant="info"
                onClick={() => this.addGroep()}
              >
                Toevoegen
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
export default Groepen;
