import React from "react";
import { InputGroup, FormControl } from "react-bootstrap";
import DataTable from "react-data-table-component";

class Log extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      all: [],
      logs: [],
      user: JSON.parse(sessionStorage.getItem("user")),
      searchText: "",
      columns: [
        {
          selector: "date",
          name: "Date",
          cell: (row) => <div>{this.dateToString(row.date)}</div>,
          sortable: true,
        },
        {
          selector: "user",
          name: "User",
          sortable: true,
        },
        {
          selector: "table",
          name: "Table",
          sortable: true,
        },
        {
          selector: "operation",
          name: "Operation",
          sortable: true,
        },
        {
          selector: "dataId",
          name: "DataID",
          sortable: true,
        },
      ],
      requestOptions:
        process.env.NODE_ENV === "development"
          ? {}
          : {
              headers: {
                Authorization: `Basic ${
                  JSON.parse(sessionStorage.getItem("user")).authdata
                }`,
              },
            },
    };

    this.fetchLogs();

    this.updateSearchText = this.updateSearchText.bind(this);
  }

  fetchLogs() {
    fetch(process.env.REACT_APP_API_URL + "dblogs", this.state.requestOptions)
      .then((res) => res.json())
      .then((data) => {
        this.setState({ logs: data, all: data });
      })
      .catch(console.log);
  }

  updateSearchText(event) {
    this.setState({ searchText: event.target.value }, () => {
      this.search();
    });
  }

  search() {
    var searchText = this.state.searchText;
    let currentList = [];
    let newList = [];
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (searchText !== "") {
        currentList = this.state.all;

        newList = currentList.filter((item) => {
          const user = item.user.toLowerCase();
          const table = item.table.toLowerCase();
          const operation = item.operation.toLowerCase();
          const filter = searchText.toLowerCase();

          return (
            user.includes(filter) ||
            table.includes(filter) ||
            operation.includes(filter)
          );
        });
      } else {
        newList = this.state.all;
      }

      this.setState({
        logs: newList,
      });
    }, 300);
  }

  dateToString(date) {
    const d = new Date(date);
    const dtf = d.toLocaleString("nl", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });

    return dtf;
  }

  render() {
    return (
      <>
        <br></br>
        <center>
          <h1>Database change log</h1>
        </center>

        <InputGroup className="mb-3">
          <InputGroup.Prepend>
            <InputGroup.Text>Zoek in logs</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl onChange={this.updateSearchText} />
        </InputGroup>
        <DataTable
          title="dbLogs"
          columns={this.state.columns}
          data={this.state.logs}
          defaultSortField="date"
          defaultSortAsc={false}
          pagination
        />

      </>
    );
  }
}

export default Log;
