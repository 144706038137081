import React from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./calendarStyle.css";
import moment from "moment";
import axios from "axios";
import { Modal, Form, Button } from "react-bootstrap";
import {
  MuiPickersUtilsProvider,
  DatePicker,
  TimePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fas, fab);

moment.updateLocale("nl-be", {
  week: {
    dow: 1, // Monday is the first day of the week.
  },
});
moment.locale("nl-be");
const localizer = momentLocalizer(moment);

class BugaCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      event: {
        group: {},
      },
      edit: false,
      isOpen: false,
      editEventTitle: "",
      editEventStart: new Date(),
      editEventEnd: new Date(),
      editEventFullDay: true,
      editEventGroup: 0,
      isMoreOpen: false,
      moreEvents: [],
      groups: [],
      user: JSON.parse(sessionStorage.getItem("user")),
      requestOptions:
        process.env.NODE_ENV === "development"
          ? {}
          : {
              headers: {
                Authorization: `Basic ${
                  JSON.parse(sessionStorage.getItem("user")).authdata
                }`,
              },
            },
    };

    this.fetchevents();
    this.fetchGroups();
  }

  fetchGroups() {
    fetch(process.env.REACT_APP_API_URL + "groups", this.state.requestOptions)
      .then((res) => res.json())
      .then((data) => {
        this.setState({ groups: data });
      })
      .catch(console.log);
  }

  fetchevents() {
    fetch(process.env.REACT_APP_API_URL + "events", this.state.requestOptions)
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          events: data.filter((x) =>
            this.state.user.roles.includes(x.group.groepsnaam)
          ),
        });
      })
      .catch(console.log);
  }

  updateEventTitle = (event) => {
    this.setState({ editEventTitle: event.target.value });
  };

  updateEventStart = (event) => {
    this.setState({ editEventStart: event.toDate() });
  };

  updateEventEnd = (event) => {
    console.log(event.toDate());
    this.setState({ editEventEnd: event.toDate() });
  };

  updateEventFullDay = (event) => {
    this.setState({ editEventFullDay: event.target.checked });
  };

  updateEventGroup = (event) => {
    this.setState({ editEventGroup: event.target.value });
  };

  addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  onEventClick(event, e) {
    console.log(this);
  }

  handleSelect = (e) => {
    this.toggleModal(e);
  };

  handleNew = (e) => {
    this.setState(
      {
        edit: true,
        editEventTitle: "",
        editEventFullDay: true,
        editEventGroup: 0,
        editEventStart: new Date(
          e.start.getFullYear(),
          e.start.getMonth(),
          e.start.getDate(),
          12,
          0,
          0
        ),
        editEventEnd: new Date(
          e.end.getFullYear(),
          e.end.getMonth(),
          e.end.getDate(),
          12,
          0,
          0
        ),
      },
      this.toggleModal(e)
    );
  };

  save = (e) => {
    var t = this;
    console.log(this.state.editEventEnd);
    var tzoffset = new Date().getTimezoneOffset() * 60000;
    axios
      .post(
        process.env.REACT_APP_API_URL + "events",
        {
          title: this.state.editEventTitle,
          start: new Date(this.state.editEventStart - tzoffset)
            .toISOString()
            .slice(0, -1),
          end: new Date(this.state.editEventEnd - tzoffset + 1)
            .toISOString()
            .slice(0, -1),
          fullDay: this.state.editEventFullDay ? 1 : 0,
          groupId: this.state.editEventGroup,
          posterId: this.state.user.id,
        },
        this.state.requestOptions
      )
      .then(function (response) {
        t.fetchevents();
      });

    this.toggleModal(this.state.event);
  };

  toggleModal(event) {
    if (event !== null) {
      this.setState({
        event: event,
      });
    }

    this.setState({
      isOpen: !this.state.isOpen,
      edit: false,
    });
  }

  toggleModalMore(events) {
    if (events !== null) {
      this.setState({
        moreEvents: events,
      });
    } else {
      this.setState({
        moreEvents: [],
      });
    }

    this.setState({
      isMoreOpen: !this.state.isMoreOpen,
    });
  }

  showMore = (e) => {
    console.log(e);
    this.toggleModalMore(e);
  };

  deleteEvent = (event) => {
    var t = this;
    axios
      .delete(
        process.env.REACT_APP_API_URL + "events/" + event.id,
        this.state.requestOptions
      )
      .then(function () {
        t.toggleModal(event);
        t.fetchevents();
      });
  };

  rendertooltip(event) {
    var returnval = "";
    if (event.fullDay) {
      returnval = event.title + "\nHele dag\nDoor:" + event.poster.name;
    } else {
      var start = new Date(event.start);
      var end = new Date(event.end);
      returnval = `${event.title}\n${("0" + start.getHours()).slice(-2)}:${(
        "0" + start.getMinutes()
      ).slice(-2)} - ${("0" + end.getHours()).slice(-2)}:${(
        "0" + end.getMinutes()
      ).slice(-2)}\nDoor: ${event.poster.name}`;
    }
    return returnval;
  }

  render() {
    return (
      <>
        <br></br>
        <center>
          <h1>Kalender</h1>
        </center>

        <Calendar
          localizer={localizer}
          events={this.state.events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: "80vh" }}
          views={["month"]}
          tooltipAccessor={this.rendertooltip}
          onSelectEvent={this.handleSelect}
          onSelectSlot={this.handleNew}
          selectable={true}
          onShowMore={this.showMore}
        />

        <Modal
          onHide={() => this.toggleModal(this.state.event)}
          show={this.state.isOpen}
          onClose={() => this.toggleModal(this.state.event)}
        >
          <Modal.Body>

          <div class="container"  hidden={this.state.edit}>
                <div class="row">
                  <div class="col-12">
                    <h3 class="klant-detail-title">Event details</h3>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-12">
                    <p class="mb-0 title-item">Titel</p>
                    <p class="detail-item">{this.state.event.title}</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <p class="mb-0 title-item">Tijdsduur</p>
                    <p class="detail-item">{this.state.event.fullDay
                      ? "Hele dag"
                      : `${(
                          "0" + new Date(this.state.event.start).getHours()
                        ).slice(-2)}:${(
                          "0" + new Date(this.state.event.start).getMinutes()
                        ).slice(-2)} - ${(
                          "0" + new Date(this.state.event.end).getHours()
                        ).slice(-2)}:${(
                          "0" + new Date(this.state.event.end).getMinutes()
                        ).slice(-2)}`}</p>
                  </div>
                  <div class="col-8">
                    <p class="mb-0 title-item">Doelgroep</p>
                    <p class="detail-item"><FontAwesomeIcon
                      title={
                        this.state.event.group
                          ? this.state.event.group.groepsnaam
                          : "Usergroup"
                      }
                      icon={[
                        this.state.event.group
                          ? this.state.event.group.icon
                            ? this.state.event.group.icon.split("%")[0]
                            : "fas"
                          : "fas",
                        this.state.event.group
                          ? this.state.event.group.icon
                            ? this.state.event.group.icon.split("%")[1]
                            : "user-friends"
                          : "user-friends",
                      ]}
                    />
                    {this.state.event.group
                      ? this.state.event.group.groepsnaam
                      : "Usergroup"}</p>
                  </div>
                  
                </div>
                <hr/>
              </div>

            <div hidden={!this.state.edit}>
              <Form.Group>
                <Form.Label>Titel</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  value={this.state.editEventTitle}
                  onChange={this.updateEventTitle}
                />
                {this.state.submitted && !this.state.editEventTitle && (
                  <Form.Text className="text-muted mb-2 mt-0">
                    Titel is verplicht
                  </Form.Text>
                )}
              </Form.Group>

              <MuiPickersUtilsProvider utils={MomentUtils}>
                <Form.Group>
                  <Form.Label>Start</Form.Label>
                  <br />
                  <DatePicker
                    value={this.state.editEventStart}
                    onChange={this.updateEventStart}
                  />
                  <TimePicker
                    ampm={false}
                    style={{
                      display: this.state.editEventFullDay ? "none" : "inline",
                    }}
                    value={this.state.editEventStart}
                    onChange={this.updateEventStart}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Eind</Form.Label>
                  <br />
                  <DatePicker
                    value={this.state.editEventEnd}
                    onChange={this.updateEventEnd}
                  />
                  <TimePicker
                    ampm={false}
                    style={{
                      display: this.state.editEventFullDay ? "none" : "inline",
                    }}
                    value={this.state.editEventEnd}
                    onChange={this.updateEventEnd}
                  />
                </Form.Group>
              </MuiPickersUtilsProvider>

              <Form.Group controlId="formBasicCheckbox">
                <Form.Check
                  custom
                  type="checkbox"
                  label="Hele dag"
                  checked={this.state.editEventFullDay}
                  onChange={this.updateEventFullDay}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Doelgroep</Form.Label>
                <Form.Control
                  as="select"
                  placeholder=""
                  value={this.state.editEventGroup}
                  onChange={this.updateEventGroup}
                >
                  <option key={"0"} name="none" value={0}>
                    -- Selecteer groep --
                  </option>
                  {this.state.groups.map((group) => (
                    <option
                      key={group.id}
                      name={group.groepsnaam}
                      value={group.id}
                    >
                      {group.groepsnaam}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              {this.state.errorMessage && (
                <div className={"alert alert-danger"}>
                  {this.state.errorMessage}
                </div>
              )}
            </div>
            <br />

            <div className="footer">
              <Button
                className="float-right"
                variant="primary"
                onClick={() => this.toggleModal(this.state.event)}
              >
                Sluit
              </Button>
              <Button
                className="float-right mr-1"
                hidden={!this.state.edit}
                variant="info"
                onClick={() => this.save()}
              >
                Opslaan
              </Button>
              {/*
              <Button
                className="float-right mr-1"
                hidden={
                  !(
                    !this.state.edit &&
                    this.state.user.roles.some(r => r === "Administratoren")
                  )
                }
                variant="info"
                onClick={() => this.toggleEdit(this.state.gebruiker)}
              >
                Wijzig
              </Button>*/}
              <Button
                className="float-right mr-1"
                hidden={
                  !(
                    !this.state.edit &&
                    (this.state.user.roles.some(
                      (r) => r === "Administratoren"
                    ) ||
                      this.state.user.id === this.state.event.posterId)
                  )
                }
                variant="danger"
                onClick={() => this.deleteEvent(this.state.event)}
              >
                Verwijder
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          onHide={() => this.toggleModalMore(this.state.moreEvents)}
          show={this.state.isMoreOpen}
          onClose={() => this.toggleModalMore(this.state.moreEvents)}
        >
          <Modal.Body>
            <h1>More events</h1>
            {this.state.moreEvents.map((event) => (
              <>
                <h5>{event.title}</h5>
                <p>
                  {event.fullDay
                    ? "Hele dag"
                    : `${("0" + new Date(event.start).getHours()).slice(-2)}:${(
                        "0" + new Date(event.start).getMinutes()
                      ).slice(-2)} - ${(
                        "0" + new Date(event.end).getHours()
                      ).slice(-2)}:${(
                        "0" + new Date(event.end).getMinutes()
                      ).slice(-2)}`}
                </p>
              </>
            ))}
            <div className="footer">
              <Button
                className="float-right"
                variant="primary"
                onClick={() => this.toggleModalMore(this.state.moreEvents)}
              >
                Sluit
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default BugaCalendar;
