import React, { Component } from "react";
import "./style.css";
import Icon from "@material-ui/core/Icon";
import Spinner from "react-bootstrap/Spinner";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import { CSVLink } from "react-csv";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Klanten extends Component {
  constructor(props) {
    super(props);
    this.timeout = 0;
    this.state = {
      isOpen: false,
      hasMore: true,
      items: [],
      edit: false,
      error: null,
      klanten: [],
      all: [],
      klant: {},
      editKlantNaam: "",
      editKlantAdres: "",
      editKlantGemeente: "",
      editKlantPostcode: "",
      editKlantTelefoon: "",
      editKlantGsm: "",
      editKlantOrigine: 21,
      editKlantInfo1: "",
      editKlantInfo2: "BU:0",
      editKlantKlantnr: "",
      response: {},
      itemIndex: 0,
      searchText: "",
      user: JSON.parse(sessionStorage.getItem("user")),
    };

    this.updateKlantNaam = this.updateKlantNaam.bind(this);
    this.updateKlantAdres = this.updateKlantAdres.bind(this);
    this.updateKlantGemeente = this.updateKlantGemeente.bind(this);
    this.updateKlantPostcode = this.updateKlantPostcode.bind(this);
    this.updateKlantTelefoon = this.updateKlantTelefoon.bind(this);
    this.updateKlantGsm = this.updateKlantGsm.bind(this);
    this.updateKlantOrigine = this.updateKlantOrigine.bind(this);
    this.updateKlantInfo1 = this.updateKlantInfo1.bind(this);
    this.updateKlantInfo2 = this.updateKlantInfo2.bind(this);
    this.updateKlantKlantnr = this.updateKlantKlantnr.bind(this);
    this.updateSearchText = this.updateSearchText.bind(this);
    this.toggleId = 0;

    if(props.match.params.id){
      this.toggleId = props.match.params.id;
    }
  }

  search() {
    var searchText = this.state.searchText;
    let currentList = [];
    let newList = [];
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (searchText !== "") {
        currentList = this.state.all;

        newList = currentList.filter((item) => {
          const name = item.naam.toLowerCase();
          const adres = item.adres.toLowerCase();
          const gemeente = item.gemeente.toLowerCase();
          const commentaar = item.info1.toLowerCase();
          const borg = item.info2.toLowerCase();
          const filter = searchText.toLowerCase();

          return (
            name.includes(filter) ||
            adres.includes(filter) ||
            commentaar.includes(filter) ||
            borg.includes(filter) ||
            gemeente.includes(filter)
          );
        });
      } else {
        newList = this.state.all;
      }

      this.setState(
        {
          klanten: newList,
          itemIndex: 0,
          items: [],
          hasMore: true,
        },
        () => {
          this.fetchMoreData();
        },
      );
    }, 300);
  }

  componentDidMount() {
    this.fetchKlanten();

   
  }

  fetchKlanten() {
    //with headers to be added later
    var requestOptions;
    if (process.env.NODE_ENV === "development") {
      requestOptions = {};
    } else {
      requestOptions = {
        headers: {
          Authorization: `Basic ${this.state.user.authdata}`,
        },
      };
    }

    fetch(process.env.REACT_APP_API_URL + "scanias", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        this.setState({ klanten: data, all: data, items: [] });
        this.search();
        if(this.toggleId !== 0){
          // eslint-disable-next-line
          var kl = this.state.klanten.find((x) => x.id == this.toggleId);
          console.log(this.toggleId)
          console.log(kl);
          this.setState({ klant: kl, isOpen : true, edit: false });
        }
      })
      .catch(console.log);
  }

  toggleModal(klant) {
    if (klant !== null) {
      this.setState({
        klant: klant,
      });
    }

    this.setState({
      isOpen: !this.state.isOpen,
      edit: false,
    });
  }

  fetchMoreData = () => {
    setTimeout(() => {
      if (this.state.items.length >= this.state.klanten.length) {
        this.setState({ hasMore: false });
        return;
      }

      this.setState({
        items: this.state.items.concat(
          this.state.klanten.slice(
            this.state.itemIndex * 20,
            this.state.itemIndex * 20 + 20,
          ),
        ),
        itemIndex: this.state.itemIndex + 1,
      });
    }, 500);
  };

  addNewKlant() {
    var klant = {
      id: 0,
      naam: "",
      adres: "",
      gemeente: "",
      postcode: "",
      tel: "",
      gsm: "",
      origine: 21,
      info1: "",
      info2: "BU:0",
      klantnr: "",
    };
    this.toggleModal(klant);
    this.toggleEdit(klant);
  }

  toggleEdit(klant) {
    this.setState({
      edit: !this.state.edit,
      editKlantNaam: klant.naam,
      editKlantAdres: klant.adres,
      editKlantGemeente: klant.gemeente,
      editKlantPostcode: klant.postcode,
      editKlantTelefoon: klant.tel,
      editKlantGsm: klant.gsm,
      editKlantOrigine: klant.origine,
      editKlantInfo1: klant.info1,
      editKlantInfo2: klant.info2.split(":")[1].trim(),
      editKlantKlantnr: klant.klantnr,
    });
  }

  updateSearchText(event) {
    this.setState({ searchText: event.target.value }, () => {
      this.search();
    });
  }

  updateKlantNaam(event) {
    this.setState({ editKlantNaam: event.target.value });
  }

  updateKlantAdres(event) {
    this.setState({ editKlantAdres: event.target.value });
  }

  updateKlantGemeente(event) {
    this.setState({ editKlantGemeente: event.target.value });
  }

  updateKlantPostcode(event) {
    this.setState({ editKlantPostcode: event.target.value });
  }

  updateKlantTelefoon(event) {
    this.setState({ editKlantTelefoon: event.target.value });
  }

  updateKlantGsm(event) {
    this.setState({ editKlantGsm: event.target.value });
  }

  updateKlantInfo1(event) {
    this.setState({ editKlantInfo1: event.target.value });
  }

  updateKlantInfo2(event) {
    this.setState({ editKlantInfo2: event.target.value });
  }

  updateKlantOrigine(event) {
    this.setState({ editKlantOrigine: event.target.value });
  }

  updateKlantKlantnr(event) {
    this.setState({ editKlantKlantnr: event.target.value });
  }

  save(event) {
    var t = this;
    var requestOptions;
    if (process.env.NODE_ENV === "development") {
      requestOptions = {};
    } else {
      requestOptions = {
        headers: {
          Authorization: `Basic ${this.state.user.authdata}`,
        },
      };
    }
    if (this.state.klant.id !== 0) {
      axios
        .put(
          process.env.REACT_APP_API_URL + "scanias/" + this.state.klant.id,
          {
            id: this.state.klant.id,
            klantnr: this.state.editKlantKlantnr
              ? parseInt(this.state.editKlantKlantnr)
              : 0,
            naam: this.state.editKlantNaam,
            adres: this.state.editKlantAdres,
            postcode: this.state.editKlantPostcode,
            gemeente: this.state.editKlantGemeente,
            tel: this.state.editKlantTelefoon,
            gsm: this.state.editKlantGsm,
            origine: parseInt(this.state.editKlantOrigine),
            info1: this.state.editKlantInfo1,
            info2:
              this.state.editKlantOrigine === 21
                ? "BU:" + this.state.editKlantInfo2
                : "BL:" + this.state.editKlantInfo2,
          },
          requestOptions,
        )
        .then(function (response) {
          t.fetchKlanten();
        });
    } else {
      axios
        .post(
          process.env.REACT_APP_API_URL + "scanias",
          {
            klantnr: this.state.editKlantKlantnr
              ? parseInt(this.state.editKlantKlantnr)
              : 0,
            naam: this.state.editKlantNaam,
            adres: this.state.editKlantAdres,
            postcode: this.state.editKlantPostcode,
            gemeente: this.state.editKlantGemeente,
            tel: this.state.editKlantTelefoon,
            gsm: this.state.editKlantGsm,
            origine: parseInt(this.state.editKlantOrigine),
            info1: this.state.editKlantInfo1,
            info2:
              this.state.editKlantOrigine === 21
                ? "BU:" + this.state.editKlantInfo2
                : "BL:" + this.state.editKlantInfo2,
          },
          requestOptions,
        )
        .then(function (response) {
          t.fetchKlanten();
        });
    }

    this.toggleModal(this.state.klant);
  }

  deleteClient(klant) {
    var t = this;
    var requestOptions;
    if (process.env.NODE_ENV === "development") {
      requestOptions = {};
    } else {
      requestOptions = {
        headers: {
          Authorization: `Basic ${this.state.user.authdata}`,
        },
      };
    }
    axios
      .delete(
        process.env.REACT_APP_API_URL + "scanias/" + klant.id,
        requestOptions,
      )
      .then(function () {
        t.fetchKlanten();
      });
    this.toggleModal(null);
  }

  render() {
    return (
      <div>
        <br></br>
        <center>
          <h1>Scania Klanten</h1>
        </center>
        <InputGroup className="mb-3">
          <InputGroup.Prepend>
            <InputGroup.Text>Zoek klanten</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl onChange={this.updateSearchText} />
        </InputGroup>
        <Button
          hidden={
            !this.state.user.roles.some(
              (r) =>
                ["Conciërges", "Administratoren", "Managers"].indexOf(r) >= 0,
            )
          }
          variant="primary mb-3"
          onClick={() => this.addNewKlant()}
        >
          Nieuwe klant toevoegen
        </Button>
        <CSVLink
          hidden={
            !this.state.user.roles.some(
              (r) => ["Administratoren", "Managers"].indexOf(r) >= 0,
            )
          }
          filename={
            "Klantenlijst-" +
            new Intl.DateTimeFormat("nl-NL", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }).format(new Date()) +
            ".csv"
          }
          data={this.state.klanten}
        >
          <Button
            variant="info mb-3" style={{float: "right"}}
          >
            <FontAwesomeIcon
              title={"file-download"}
              icon={["fas", "file-download"]}
            /> Download klantenlijst
          </Button>
        </CSVLink>
        <InfiniteScroll
          dataLength={this.state.items.length}
          next={this.fetchMoreData}
          hasMore={this.state.hasMore}
          loader={
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          }
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Geen verdere klanten gevonden</b>
            </p>
          }
        >
          {this.state.items.map((klant) => (
            <div
              className="card"
              key={klant.id}
              onClick={() => this.toggleModal(klant)}
            >
              <div
                className={"card-body " + (klant.origine === 21 ? "" : "light")}
              >
                <h5 className="card-title">{klant.naam}</h5>
                <h6 className="card-text">
                  <Icon>home</Icon>
                  <span
                    style={{
                      position: "relative",
                      top: "-6px",
                      marginLeft: "6px",
                    }}
                  >
                    {klant.adres}, {klant.postcode} {klant.gemeente}
                  </span>
                </h6>
                <h6 className="card-text">
                  <Icon>phone</Icon>
                  <span
                    style={{
                      position: "relative",
                      top: "-6px",
                      marginLeft: "6px",
                    }}
                  >
                    {klant.tel}
                  </span>
                </h6>
                <h6 className="card-text">
                  <Icon>smartphone</Icon>
                  <span
                    style={{
                      position: "relative",
                      top: "-6px",
                      marginLeft: "6px",
                    }}
                  >
                    {klant.gsm}
                  </span>
                </h6>
                <h6 className="card-text">
                  <Icon>comment</Icon>
                  <span
                    style={{
                      position: "relative",
                      top: "-6px",
                      marginLeft: "6px",
                    }}
                  >
                    {klant.info1}
                  </span>
                </h6>
                <Badge
                  pill
                  variant="primary"
                  className={
                    klant.info2.includes("0")
                      ? "blue"
                      : klant.info2.includes("++")
                      ? "green"
                      : klant.info2.includes("+")
                      ? "yellow"
                      : "red"
                  }
                >
                  {klant.info2}
                </Badge>
              </div>
            </div>
          ))}
        </InfiniteScroll>
        <br />
        <br />
        <Modal
          onHide={() => this.toggleModal(this.state.klant)}
          show={this.state.isOpen}
          onClose={() => this.toggleModal(this.state.klant)}
        >
          <Modal.Body>
            <Container>
              <div class="container" hidden={this.state.edit}>
                <div class="row">
                  <div class="col-12">
                    <h3 class="klant-detail-title">Klant Details</h3>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-12">
                    <p class="mb-0 title-item">Klant</p>
                    <p class="detail-item">{this.state.klant.naam}</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <p class="mb-0 title-item">Adres</p>
                    <p class="detail-item">{this.state.klant.adres}</p>
                  </div>
                  <div class="col-4">
                    <p class="mb-0 title-item">Postcode</p>
                    <p class="detail-item">{this.state.klant.postcode}</p>
                  </div>
                  <div class="col-4">
                    <p class="mb-0 title-item">Gemeente</p>
                    <p class="detail-item">{this.state.klant.gemeente}</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <p class="mb-0 title-item">Telefoon</p>
                    <p class="detail-item">{this.state.klant.tel}</p>
                  </div>
                  <div class="col-4">
                    <p class="mb-0 title-item">Gsm</p>
                    <p class="detail-item">{this.state.klant.gsm}</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <p class="mb-0 title-item">Borg</p>
                    <p class="detail-item">{this.state.klant.info2}</p>
                  </div>
                  <div class="col-8">
                    <p class="mb-0 title-item">Commentaar</p>
                    <p class="detail-item">{this.state.klant.info1}</p>
                  </div>
                </div>
                <hr />
              </div>
              <div hidden={!this.state.edit}>
                <Form.Group>
                  <Form.Label>Naam</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    value={this.state.editKlantNaam}
                    onChange={this.updateKlantNaam}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Adres</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    value={this.state.editKlantAdres}
                    onChange={this.updateKlantAdres}
                  />
                </Form.Group>
                <Row>
                  <Col sm="4">
                    <Form.Group>
                      <Form.Label>Postcode</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        value={this.state.editKlantPostcode}
                        onChange={this.updateKlantPostcode}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="8">
                    <Form.Group>
                      <Form.Label>Gemeente</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        value={this.state.editKlantGemeente}
                        onChange={this.updateKlantGemeente}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group>
                  <Form.Label>Telefoon</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    value={this.state.editKlantTelefoon}
                    onChange={this.updateKlantTelefoon}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Gsm</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    value={this.state.editKlantGsm}
                    onChange={this.updateKlantGsm}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Commentaar</Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder=""
                    value={this.state.editKlantInfo1}
                    onChange={this.updateKlantInfo1}
                  />
                </Form.Group>
                <Row>
                  <Col sm="6">
                    <Form.Group>
                      <Form.Label>Borg</Form.Label>
                      <Form.Control
                        as="select"
                        placeholder="BU:--/-/0/+/++    BL:--/-/0/+/++"
                        value={this.state.editKlantInfo2}
                        onChange={this.updateKlantInfo2}
                      >
                        <option name="Contant" value="--">
                          -- (Absoluut geen borg)
                        </option>
                        <option name="Geen" value="-">
                          - (Geen borg)
                        </option>
                        <option name="Navragen" value="0">
                          0 (Navragen)
                        </option>
                        <option name="Laag" value="+">
                          + (Tot €750)
                        </option>
                        <option name="Hoog" value="++">
                          ++ (Tot €2500)
                        </option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col sm="6">
                    <Form.Group>
                      <Form.Label>Origine</Form.Label>
                      <Form.Control
                        as="select"
                        placeholder=""
                        value={this.state.editKlantOrigine}
                        onChange={this.updateKlantOrigine}
                      >
                        <option name="Oevel" value="21">
                          Oevel
                        </option>
                        <option name="Limburg" value="7">
                          Limburg
                        </option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group>
                  <Form.Label>Klantnummer</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder=""
                    value={this.state.editKlantKlantnr}
                    onChange={this.updateKlantKlantnr}
                  />
                </Form.Group>
              </div>
              <br />
              <div className="footer">
                <Button
                  className="float-right"
                  variant="primary"
                  onClick={() => this.toggleModal(this.state.klant)}
                >
                  Sluit
                </Button>
                <Button
                  className="float-right mr-1"
                  hidden={!this.state.edit}
                  variant="info"
                  onClick={() => this.save()}
                >
                  Opslaan
                </Button>
                <Button
                  className="float-right mr-1"
                  hidden={
                    !(
                      !this.state.edit &&
                      this.state.user.roles.some(
                        (r) =>
                          ["Conciërges", "Administratoren", "Managers"].indexOf(
                            r,
                          ) >= 0,
                      )
                    )
                  }
                  variant="info"
                  onClick={() => this.toggleEdit(this.state.klant)}
                >
                  Wijzig
                </Button>
                <Button
                  className="float-right mr-1"
                  hidden={
                    !(
                      !this.state.edit &&
                      this.state.user.roles.some(
                        (r) => ["Administratoren", "Managers"].indexOf(r) >= 0,
                      )
                    )
                  }
                  variant="danger"
                  onClick={() => this.deleteClient(this.state.klant)}
                >
                  Verwijder
                </Button>
              </div>
            </Container>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default Klanten;
