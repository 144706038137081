import React, { useState } from "react";
import Klanten from "./KlantComponent/klanten";
import Gebruikers from "./GebruikersComponent/gebruikers";
import Groepen from "./GroepenComponent/groepen";
import Nummers from "./NummersComponent/nummers";
import Announcements from "./AnnouncementComponent/announcements";
import Interventions from "./InterventionsComponent/interventions";
import { Flowchart } from "./FlowchartComponent/flowchart";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { slide as Menu } from "react-burger-menu";
import { PrivateRoute } from "../helpers/PrivateRoute";
import RouteGuard from "../helpers/RouteGuard";
import scaniaLogo from "./imgs/logo.png";
import { useHistory} from "react-router-dom";
import Calendar from "./CalendarComponent/Calendar";
import Log from "./LogComponent/Log";



const Main = () => {
  const [user] = useState(JSON.parse(sessionStorage.getItem("user")));
  const [isMenuOpen, setisMenuOpen] = useState(false);
  const history = useHistory();
  
  if(window.location.pathname === "/app" || window.location.pathname === "/app/"){
    history.push('/app/klanten');
  }

  function logOut() {
    sessionStorage.removeItem("user");
    window.location.reload(true);
  }

  function changePW() {
    history.push('/changepassword')
  }

  function setActive(val){
    history.push('/app/' + val)
    setisMenuOpen(false);
  }

  var isMenuOpenFn = function(state) {
    setisMenuOpen(state.isOpen)
  };

  return (
    <>
        <Menu isOpen={isMenuOpen} onStateChange={isMenuOpenFn}>
                <Image src={scaniaLogo} fluid />
                <button 
                  onClick={() => setActive("klanten")}
                  className="mr-1 ml-1 mt-1 menuButton"
                >
                  Klanten
                </button>
                <button
                  hidden={!(user.roles.some(r => ["Conciërges", "Administratoren", "Managers"].indexOf(r) >= 0))}
                  onClick={() => setActive("interventions")}
                  className="mr-1 ml-1 mt-1 menuButton"
                >
                  Interventies
                </button>
                <button
                  hidden={!(user.roles.some(r => ["Conciërges", "Administratoren"].indexOf(r) >= 0))}
                  onClick={() => setActive("flowchart")}
                  className="mr-1 ml-1 mt-1 menuButton"
                >
                  Flowchart
                </button>
                <button
                  onClick={() => setActive("nummers")}
                  className="mr-1 ml-1 mt-1 menuButton"
                >
                  Nummers
                </button>
                <button
                  hidden={!(user.roles.some(r => r === "Administratoren"))}
                  onClick={() => setActive("gebruikers")}
                  className="mr-1 ml-1 mt-1 menuButton"
                >
                  Gebruikers
                </button>
                <button
                  hidden={!(user.roles.some(r => r === "Administratoren"))}
                  onClick={() => setActive("groepen")}
                  className="mr-1 ml-1 mt-1 menuButton"
                >
                  Groepen
                </button>
                <button
                  onClick={() => setActive("mededelingen")}
                  className="mr-1 ml-1 mt-1 menuButton"
                >
                  Mededelingen
                </button>
                <button
                  onClick={() => setActive("kalender")}
                  className="mr-1 ml-1 mt-1 menuButton"
                >
                  Kalender
                </button>
                <button
                  hidden={!(user.roles.some(r => r === "Ontwikkelaar"))}
                  onClick={() => setActive("logs")}
                  className="mr-1 ml-1 mt-1 menuButton"
                >
                  Logs
                </button>
                <p style={{ fontSize: "12px", marginTop: "30px" }}>
                  Ingelogd als:&nbsp;
                  <b>{user.name}</b>
                  <br />
                  <button className="logOutButton"
                    style={{ fontSize: "12px" }}
                    onClick={changePW}
                  >
                    Wachtwoord wijzigen
                  </button><br/>
                  <button className="logOutButton"
                    style={{ fontSize: "12px" }}
                    onClick={logOut}
                  >
                    Log uit
                  </button>
                </p>
                <div className={"menuFooter"}>
                  
                  <a href="http://www.jitsebeyens.be">&copy; {new Date().getFullYear()} - Jitse Beyens, All Rights Reserved</a>
                  </div>
        </Menu>
        <div className="App-bg">
        <div className="App">
          <Container>
            <Row>
              <Col>
                <PrivateRoute exact path="/app/klanten/:id" component={Klanten} />
                <PrivateRoute exact path="/app/klanten" component={Klanten} />
                <RouteGuard path="/app/flowchart" component={Flowchart} roles={["Conciërges","Administratoren"]} />
                <RouteGuard path="/app/interventions" component={Interventions} roles={["Conciërges","Administratoren", "Managers"]} />
                <RouteGuard path="/app/gebruikers" component={Gebruikers} roles={["Administratoren"]} />
                <RouteGuard path="/app/groepen" component={Groepen} roles={["Administratoren"]}/>
                <PrivateRoute path="/app/mededelingen" component={Announcements} />
                <PrivateRoute path="/app/nummers" component={Nummers} />
                <PrivateRoute path="/app/kalender" component={Calendar}/>
                <RouteGuard path="/app/logs" component={Log} roles={["Ontwikkelaar"]}/>
              </Col>
            </Row>
          </Container>
          </div>
          </div>
    </>
  );
};

export default Main;
