import React from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import axios from "axios";

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      oldPassword: "",
      password: "",
      checkPassword: "",
      submitted: false,
      loading: false,
      error: "",
      user: JSON.parse(sessionStorage.getItem("user"))
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { oldPassword, password, checkPassword, user } = this.state;

    // stop here if form is invalid
    if (!(oldPassword && password && checkPassword)) {
      return;
    }

    this.setState({ loading: true });

    if (password !== checkPassword) {
      this.setState({
        error: "Wachtwoorden komen niet overeen",
        loading: false
      });
      return;
    }

    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${this.state.user.authdata}`
      }
    };

    axios
      .post(
        process.env.REACT_APP_API_URL + `users/login/`,
        {
          username: user.username,
          password: oldPassword
        },
        requestOptions
      )
      .then(data => {
        if (data.data) {
          var t = this;
          console.log(user);
          axios
            .put(
              process.env.REACT_APP_API_URL + "users/changepw/" + user.id,
              {
                id: user.id,
                pwchanged: 1,
                password: password
              },
              {
                headers: {
                  Authorization: `Basic ${user.authdata}`
                }
              }
            )
            .then(function(response) {
              data = response.data;
              data.authdata = window.btoa(response.data.username + ":" + password);
              data.roles = [];
              data.groupUsers.forEach(groupUser =>{
                data.roles.push(groupUser.group.groepsnaam)
              });
              sessionStorage.setItem("user", JSON.stringify(data));
              t.props.history.push("/app");
            });
        } else {
          this.setState({
            error: "Huidig wachtwoord incorrect",
            loading: false
          });
          return;
        }
      });
  }

  render() {
    const {
      oldPassword,
      password,
      checkPassword,
      submitted,
      loading,
      error
    } = this.state;
    return (
      <div className="App-bg">
        <div className="App">
          <br></br>
          <br></br>
          <Container>
            <Card>
              <Card.Body>
                <Card.Title>Change Password</Card.Title>
                <Card.Text>
                  <form name="form" onSubmit={this.handleSubmit}>
                    <div
                      className={
                        "form-group" +
                        (submitted && !oldPassword ? " has-error" : "")
                      }
                    >
                      <label htmlFor="oldPassword">Huidig wachtwoord</label>
                      <input
                        type="password"
                        className="form-control"
                        name="oldPassword"
                        value={oldPassword}
                        onChange={this.handleChange}
                      />
                      {submitted && !oldPassword && (
                        <div className="help-block">
                          Huidig wachtwoord is verplicht
                        </div>
                      )}
                    </div>
                    <div
                      className={
                        "form-group" +
                        (submitted && !password ? " has-error" : "")
                      }
                    >
                      <label htmlFor="password">Wachtwoord</label>
                      <input
                        type="password"
                        className="form-control"
                        name="password"
                        value={password}
                        onChange={this.handleChange}
                      />
                      {submitted && !password && (
                        <div className="help-block">
                          Nieuw wachtwoord is verplicht
                        </div>
                      )}
                    </div>
                    <div
                      className={
                        "form-group" +
                        (submitted && !checkPassword ? " has-error" : "")
                      }
                    >
                      <label htmlFor="checkPassword">
                        Nieuw wachtwoord opnieuw ingeven
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        name="checkPassword"
                        value={checkPassword}
                        onChange={this.handleChange}
                      />
                      {submitted && !checkPassword && (
                        <div className="help-block">
                          Wachtwoord check is verplicht
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <button className="btn btn-primary" disabled={loading}>
                        Verander wachtwoord
                      </button>
                      {loading}
                    </div>
                    {error && (
                      <div className={"alert alert-danger"}>{error}</div>
                    )}
                  </form>
                </Card.Text>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </div>
    );
  }
}

export { ChangePassword };
