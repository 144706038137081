import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { Typeahead } from 'react-bootstrap-typeahead';
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import axios from "axios";

class Interventions extends Component {
  constructor(props) {
    super(props);
    this.timeout = 0;
    this.state = {
      klanten: [],
      interventionTypes: [],
      interventions: [],
      intervention: {},
      bailType: 0,
      editInterventionType: 0,
      editInterventionBail: 0.0,
      editInterventionAction: "",
      editInterventionRemark: "",
      editInterventionKlant: [],
      editInterventionDate: new Date(),
      user: JSON.parse(sessionStorage.getItem("user")),
      requestOptions:
        process.env.NODE_ENV === "development"
          ? {}
          : {
              headers: {
                Authorization: `Basic ${
                  JSON.parse(sessionStorage.getItem("user")).authdata
                }`,
              },
            },
      
    }
  }

  updateInterventionType = (event) => {
    this.setState({ editInterventionType: event.target.value });
  };

  updateInterventionBail = (event) => {
    this.setState({ editInterventionBail: event.target.value });
  };

  updateInterventionAction = (event) => {
    this.setState({ editInterventionAction: event.target.value });
  };

  updateInterventionRemark = (event) => {
    this.setState({ editInterventionRemark: event.target.value });
  };

  updateInterventionKlant = (event) => {
    this.setState({ editInterventionKlant: event });
  };

  updateInterventionDate = (event) => {
    this.setState({ editInterventionDate: event.toDate() });
  };

  componentDidMount() {
    this.fetchInterventions();
    this.fetchKlanten();
    this.fetchInterventionTypes();
  }

  toggleModal(intervention) {
    if (intervention !== null) {
      this.setState({
        intervention: intervention,
      });
    }

    this.setState({
      isOpen: !this.state.isOpen,
      edit: false,
    });
  }

  toggleEdit(intervention) {
    this.setState({
      edit: !this.state.edit,
      editInterventionType : intervention.interventionTypeId,
      editInterventionBail : intervention.bail,
      editInterventionAction : intervention.action,
      editInterventionRemark : intervention.remark,
      editInterventionKlant : intervention.klant,
      editInterventionDate : intervention.date,
    });
  }

  addNewIntervention() {
    var interv = {
      id: 0,
      interventionTypeId: "",
      klant: [],
      bail: "",
      action: "",
      remark: "",
      date: new Date(),
    };
    this.toggleModal(interv);
    this.toggleEdit(interv);
  }

  fetchInterventions() {
    fetch(process.env.REACT_APP_API_URL + "intervention", this.state.requestOptions)
      .then(res => res.json())
      .then(data => {
        this.setState({
          interventions: data,
        });
      })
      .catch(console.log);
  }

  fetchKlanten() {
    fetch(process.env.REACT_APP_API_URL + "scanias", this.state.requestOptions)
      .then(res => res.json())
      .then(data => {
        data = data.map(function(obj){
          obj['label'] = obj['naam'];
          delete obj['naam'];
          return obj;
        });
        this.setState({
          klanten: data,
        });
      })
      .catch(console.log);
  }

  fetchInterventionTypes() {
    fetch(process.env.REACT_APP_API_URL + "interventiontype", this.state.requestOptions)
      .then(res => res.json())
      .then(data => {
        this.setState({
          interventionTypes: data,
          bailType: data.find(z => z.name.includes("borg")).id,
        });
      })
      .catch(console.log);
  }

  dateToString(date) {
    const d = new Date(date);
    const dtf = d.toLocaleString("nl", {
      year: "numeric",
      month: "long",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });

    return dtf;
  }

  save(event) {
    var t = this;
    var requestOptions;
    var tzoffset = new Date().getTimezoneOffset() * 60000;
    if (process.env.NODE_ENV === "development") {
      requestOptions = {};
    } else {
      requestOptions = {
        headers: {
          Authorization: `Basic ${this.state.user.authdata}`,
        },
      };
    }
    if (this.state.intervention.id !== 0) {
      axios
        .put(
          process.env.REACT_APP_API_URL + "scanias/" + this.state.klant.id,
          {
            id: this.state.klant.id,
            klantnr: this.state.editKlantKlantnr
              ? parseInt(this.state.editKlantKlantnr)
              : 0,
            naam: this.state.editKlantNaam,
            adres: this.state.editKlantAdres,
            postcode: this.state.editKlantPostcode,
            gemeente: this.state.editKlantGemeente,
            tel: this.state.editKlantTelefoon,
            gsm: this.state.editKlantGsm,
            origine: parseInt(this.state.editKlantOrigine),
            info1: this.state.editKlantInfo1,
            info2:
              this.state.editKlantOrigine === 21
                ? "BU:" + this.state.editKlantInfo2
                : "BL:" + this.state.editKlantInfo2,
          },
          requestOptions,
        )
        .then(function (response) {
          t.fetchKlanten();
        });
    } else {
      axios
        .post(
          process.env.REACT_APP_API_URL + "intervention",
          {
            ScaniaId: this.state.editInterventionKlant
              ? parseInt(this.state.editInterventionKlant[0]?.id)
              : null,
              // eslint-disable-next-line
            Bail: this.state.editInterventionType == this.state.bailType 
            ? this.state.editInterventionBail
            : null
            ,
            interventionTypeId: this.state.editInterventionType,
            Action: this.state.editInterventionAction,
            remark: this.state.editInterventionRemark,
            date: new Date(this.state.editInterventionDate - tzoffset)
            .toISOString()
            .slice(0, -1),
            creatorId: this.state.user.id
          },
          requestOptions,
        )
        .then(function (response) {
          t.fetchInterventions();
        });
    }

    this.toggleModal(this.state.intervention);
  }



  render() {
    return (
      <>
      <br></br>
        <center>
          <h1>Overzicht interventies</h1>
        </center>
        <Button
          hidden={
            !this.state.user.roles.some(
              (r) =>
                ["Conciërges", "Administratoren", "Managers"].indexOf(r) >= 0,
            )
          }
          variant="primary mb-3"
          onClick={() => this.addNewIntervention()}
        >
          Nieuwe interventie toevoegen
        </Button>
        {this.state.interventions.map((intervention) => (
            <div
              className="card"
              key={intervention.id}
            >
              <div
                className={"card-body"}
              >
                <h5 className="card-title"><b>{this.dateToString(intervention.date)} : {intervention.interventionType.name}</b></h5>
                <br/>
                {intervention.scania ?
                  <h6 className="card-text">
                  <b>Klant: </b><a href={"klanten/" + intervention.scania.id}>{intervention.scania.naam}</a>
                  </h6>
                  :
                  ""
                }
              {intervention.bail?
                <h6>
                  <b>Borg: </b>{intervention.bail}
                </h6>
              :
                ""
              }
              {intervention.action?
                <h6>
                  <b>Actie: </b>{intervention.action}
                </h6>
              :
                ""
              }
              {intervention.remark?
                <h6>
                  <b>Opmerking: </b>{intervention.remark}
                </h6>
              :
                ""
              }
                <br />
                <p style={{fontSize: "0.7em"}}><em>Aangemaakt door {intervention.creator.name}</em></p>
              </div>
            </div>
          ))}



        <Modal
          onHide={() => this.toggleModal(this.state.intervention)}
          show={this.state.isOpen}
          onClose={() => this.toggleModal(this.state.intervention)}
        >
          <Modal.Body>
            <Container>
              <div hidden={!this.state.edit}>
              <Form.Group>
                  <Form.Label>Type</Form.Label>
                      <Form.Control
                        as="select"
                        placeholder=""
                        value={this.state.editInterventionType}
                        onChange={this.updateInterventionType}
                      >
                        <option name={""} value={0} key={0}>
                            {""}
                          </option>
                        {this.state.interventionTypes.map((type) => (
                          <option name={type.name} value={type.id} key={type.id}>
                            {type.name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    
                    {
                    // eslint-disable-next-line
                    this.state.editInterventionType == this.state.bailType ?
                    <Form.Group>
                    <Form.Label>Borg</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder=""
                      value={this.state.editInterventionBail}
                      onChange={this.updateInterventionBail}
                    />
                  </Form.Group>
                  :
                    ""
                  }
                <Form.Group>
                  <Form.Label>Klant</Form.Label>
                  <Typeahead
                    clearButton
                    id="klanten"
                    onChange={this.updateInterventionKlant}
                    options={this.state.klanten}
                    placeholder="Kies een klant"
                    selected={this.state.editInterventionKlant}
                  />
                </Form.Group>
                
                <Form.Group>
                  <Form.Label>Actie</Form.Label>
                  <Form.Control
                    type="textarea"
                    placeholder=""
                    value={this.state.editInterventionAction}
                    onChange={this.updateInterventionAction}
                  />
                </Form.Group>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                <Form.Group>
                  <Form.Label>Datum</Form.Label>
                  <br />
                  <DatePicker
                    value={this.state.editInterventionDate}
                    onChange={this.updateInterventionDate}
                  />
                  <TimePicker
                    ampm={false}
                    value={this.state.editInterventionDate}
                    onChange={this.updateInterventionDate}
                  />
                </Form.Group>
                </MuiPickersUtilsProvider>
                <Form.Group>
                  <Form.Label>Opmerking</Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder=""
                    value={this.state.editInterventionRemark}
                    onChange={this.updateInterventionRemark}
                  />
                </Form.Group>
                
              </div>
              <br />
              <div className="footer">
                <Button
                  className="float-right"
                  variant="primary"
                  onClick={() => this.toggleModal(this.state.intervention)}
                >
                  Sluit
                </Button>
                <Button
                  className="float-right mr-1"
                  hidden={!this.state.edit}
                  variant="info"
                  onClick={() => this.save()}
                >
                  Opslaan
                </Button>
                <Button
                  className="float-right mr-1"
                  hidden={
                    !(
                      !this.state.edit &&
                      this.state.user.roles.some(
                        (r) =>
                          ["Conciërges", "Administratoren", "Managers"].indexOf(
                            r,
                          ) >= 0,
                      )
                    )
                  }
                  variant="info"
                  onClick={() => this.toggleEdit(this.state.klant)}
                >
                  Wijzig
                </Button>
                <Button
                  className="float-right mr-1"
                  hidden={
                    !(
                      !this.state.edit &&
                      this.state.user.roles.some(
                        (r) => ["Administratoren", "Managers"].indexOf(r) >= 0,
                      )
                    )
                  }
                  variant="danger"
                  onClick={() => this.deleteClient(this.state.klant)}
                >
                  Verwijder
                </Button>
              </div>
            </Container>
          </Modal.Body>
        </Modal>


      </>
    );
  }
}

export default Interventions;
