import React from "react";
import { Route, useHistory } from "react-router-dom";
import { PrivateRoute } from "./helpers/PrivateRoute";
import { LoginPage } from "./components/LoginPage";
import { ChangePassword } from "./components/ChangePassword";
import Main from "./components/main";
import "./App.css";

function App() {
  const history = useHistory();

  if(window.location.pathname === "/"){
    history.push('/app');
  }

  return (
    <>
        <PrivateRoute path="/app" component={Main} />
        <PrivateRoute path="/changepassword" component={ChangePassword} />
        <Route path="/login" component={LoginPage} />
    </>
  );
}

export default App;
